@font-face {
  src: local("SF Pro Medium"),url('../fonts/FontsFree-Net-SFProDisplay-Medium.ttf');
  font-family:'SF Pro Medium';
  font-style: normal;
}

@font-face {
  src: local("SF Pro Regular"),url('../fonts/FontsFree-Net-SFProDisplay-Regular-1.ttf');
  font-family:'SF Pro Regular';
  font-style: normal;
}

.shop-mobile{
  display: none;
}

@media screen and (max-width: 480px){
  .shop-mobile{
    display: block;
  }

  .shop-web{
    display: none;
  }

  .faq-img {
    max-width: 360px;
    width: 100%;
  }
}

@media screen and (max-width: 380px){
  .faq-img {
    max-width: 250px;
    width: 100%;
  }
}

