
.payment-mobile {
  display: none;
}

@media screen and (max-width: 480px) {

  .payment-page {
    display: none;
  }

  .payment-mobile {
    display: block;
    background: #F5F5F5;
    min-height: 100vh !important;
    padding: 30px 0 0 0;
  }

  .mobile-start-data-picker, .mobile-end-data-picker {
    display: block;
    width: 45%;
  }

  .data-picker-container {
    margin: 50px auto 0;
    display: flex;
    justify-content: space-around;
  }

  .left-part {
    max-width: 190px;
    width: 100%;
  }

  .payment-mobile-container {
    width: 90%;
    padding: 16px;
    border-radius: 8px;
    background: white;
    margin: 0 auto 16px;
    display: flex;
    justify-content: space-between;
  }

  .left-part-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #1589EE;
  }

  .left-part-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  .amount {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: right;
  }

  .balance {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
  }

  .loader {
    display: flex;
    justify-content: center;
  }

  .current-date {
    display: block;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #717681;
    margin: 15px 0;
  }


  .show-more-payments-btn {
    width: 100%;
    text-align: center;
    color: #1589EE;
    padding: 5px 0;
  }

  .show-more-payments-btn{
    width: 100%;
    text-align: center;
    color: #1589EE;
    padding: 5px 0 20px 0;
  }
}