.buy-proxy-confirm-btn{
  color: white;
  background-color: #4c97ff;
  text-align: center;
  padding: 8px;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 5px;
}

