.add-funds-mobile, .mobile, .shop-mobile, .my-proxies-mobile{
  display: none;
}

@media screen and (max-width: 480px){
  .web{
    display: none;
  }
  .mobile{
    display: block;
  }

  .mobile-start-data-picker .MuiFormControl-root .MuiInputBase-root::before,
  .mobile-end-data-picker .MuiFormControl-root .MuiInputBase-root::before{
    display: none !important;
  }

  .mobile-start-data-picker .MuiFormControl-root,
  .mobile-end-data-picker .MuiFormControl-root{
    /* width: 120px !important; */
    height: 56px !important;
    background: white;
    border-radius: 5px !important
  }

  .mobile-start-data-picker .MuiFormControl-root label,
  .mobile-end-data-picker .MuiFormControl-root label{
    position: absolute;
    top: -8px;
    left: 10px;
  }

  .mobile-start-data-picker .MuiInput-underline:after,
  .mobile-end-data-picker .MuiInput-underline:after{
    display: none !important;
  }

  /* .payment-page .MuiBox-root .MuiBox-root{
    border: none !important;
  } */

  .add-funds-mobile{
    display: block;
  }

  .add-funds-mobile-page{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .add-funds-mobile-page .MuiFormControl-root{
    width: 90%;
    margin: auto;
    display: flex;
  }

  .add-funds-mobile-page .MuiFormControl-root .MuiInputBase-root{
    width: 100% !important;
    height: 100% !important;
    background: white !important;
    border-radius: 8px !important;
  }

  .add-funds-mobile-page .MuiFormControl-root .MuiInputBase-root fieldset{
    display: none !important;
  }

  .amount-mobile-input .MuiInputBase-root input{
    margin-left: 5px !important;
  }

  .shop-web{
    display: none;
  }

  .shop-mobile{
    display: block;
    padding:70px 16px 16px 16px;
    background: #F5F5F5;
    min-height: 100vh;
    height: 100%;
  }

  .more-countries-container .MuiBox-root{
    background: white !important;
    width: 100%;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    border: none
  }

  .shop-filter .MuiTableRow-root{
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .shop-filter-page .MuiAutocomplete-root{
    background-color: white;
    padding: 10px 16px;
    margin-bottom: 16px;
    border-radius: 8px;
    display: flex;
    width: 90%;
    margin: auto;
  }

  .shop-filter-page .MuiBox-root{
    width: 90%;
    margin: auto
  }

  .central-zip-container .MuiFormControl-root{
    background: white;
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    margin-bottom: 16px;
  }

  .search-radius-container .MuiSlider-root{
    width: 90%;
    margin: auto;
  }

  .my-proxies-mobile{
    display: block;
  }

  .my-proxies-web{
    display: none;
  }

  .shop-id-page .MuiBox-root .css-1gsv261 {
    background: white !important;
  }

  .shop-id-page .MuiBox-root .MuiTabs-root .MuiTabs-scroller .MuiButtonBase-root{
    padding: 0 !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(113, 118, 129, 1) !important;
    text-transform: none !important;
    width: 25% !important;
  }

  .MuiBox-root .css-19kzrtu{
    margin-bottom: 0px !important;
  }

  .add-note-input-container .MuiBox-root .css-19kzrtu{
    padding: 0 !important;
  }

  .my-proxies-details-page .MuiBox-root{
    height: fit-content;
  }

  .my-proxies-details-page .MuiBox-root .MuiBox-root{
    padding: 0 !important
  }

  .my-proxies-details-page .MuiBox-root .MuiTabs-flexContainer{
    background: white !important;
    width: 100%;
    overflow: scroll !important
  }

  .my-proxies-details-page .MuiBox-root .MuiTabs-flexContainer button{
    width: 33%;
    padding: 0 !important
  }

  .my-proxies-details-page .MuiBox-root .css-19kzrtu{
    margin-bottom: 0 !important;
  }

  .confirm-purchase-dialog .MuiPaper-root .MuiDialogContent-root{
    font-size: 12px !important;
  }

  .refund-mob-btn{
    color: #4c97ff;
    border: 1px solid rgba(76, 151, 255, 0.5);
    padding: 5px 15px;
    border-radius: 5px;
    text-decoration: none;
  }

  .popup-cont .MuiButtonBase-root{
    border: none !important;
    text-transform: none !important;
    margin: 0 !important;
    padding: 0 !important;
    color: black !important;
    text-align: left !important;
    font-weight: 400 !important;
  }

  
}