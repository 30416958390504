.confirm-purchase-page{
  min-height: 100vh;
  height: 100%;
  padding: 70px 0 0 0;
  background: #F5F5F5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.deadline, .crypto-transaction{
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(113, 118, 129, 1);
  margin-bottom: 16px;
  width: 90%;
  margin: auto;
}

.min-amount, .popups{
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(73, 78, 89, 1);
  margin-bottom: 16px;
  width: 90%;
  margin: auto;
}

.crypto-transaction{
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(113, 118, 129, 1)
}

.crypto-transaction a{
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(21, 137, 238, 1);
  text-decoration: none;

}

.buy-proxy-btn-container{
  width: 100%;
  background: white;
  padding: 16px
}

.buy-proxy-btn-container .MuiButtonBase-root{
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(21, 137, 238, 1);
  text-decoration: none;
  color: white;
  width: 100%;
  padding: 16px
}